.main{
    width: 100%;
    margin: 20px auto;
    height: 200px;
    display: flex;
    justify-content: space-between;
    
    .card{
        position: relative;
        width: 25%;
        border-radius: 10px;
    }
    .card :hover{
        transform: scale(1.1);
        transition-duration: 500ms;
    }
    .currentImageTitle{
        position: absolute;
        top: 0px;
        left: 10%;
        color: #00276A;
        background-color: white;
        width: 80%;
        text-align: center;
        line-height: 20px;
        padding: 6px 3px;
        border-radius: 0px 0px 5px 5px;
        box-shadow: 2px 2px 6px black;
    }

    .main-paper{
        width: 70% !important;
        margin-left: 10px;
        border-radius: 10px;

        .main-paper-top{
            display: flex;
            justify-content: space-around;

            .main-left{
                width: 200px;
                text-align: center;

                .left-top{
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-around;
                    font-size: 13px;
                    
                    .d{
                        border-radius: 50%;
                        background-color: rgb(5, 184, 79);
                        width: 30px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: white;
                    }
                    .h{
                        border-radius: 50%;
                        background-color: #FCB026;
                        width: 30px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: white;
                    }
                    .m{
                        border-radius: 50%;
                        background-color: #00276A;
                        width: 30px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: white;
                    }
                    .s{
                        border-radius: 50%;
                        background-color: gray;
                        width: 30px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: white;
                    }
                }
                .left-bottom{
                    display: flex;
                    justify-content: space-around;
                }
            }

            .main-center{
                margin-top: 10px;
                text-align: center;
                color: #00276A;
            }

        }

        .main-paper-bottom{
            margin: 15px 10px;

            .text{
                overflow: hidden !important;
                height: 110px !important;
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .main{
        .main-paper{
            width: 60% !important;
        }
    }
}
@media only screen and (max-width: 650px) {
    .main{
        justify-content: space-between;
    }
}

.event{
    width: 300px;
    margin: 20px 0px;
    display: flex;
    flex-direction: column !important;

    .card{
        background-color: #00276A;
        position: relative;
        border-radius: 10px;
        
    }
    .card :hover{
        transform: scale(1.1);
        transition-duration: 500ms;
    }
    .currentImageTitle{
        position: absolute;
        top: 0px;
        left: 10%;
        color: #00276A;
        background-color: white;
        width: 80%;
        text-align: center;
        line-height: 20px;
        padding: 6px 3px;
        border-radius: 0px 0px 5px 5px;
        box-shadow: 2px 2px 6px black;
    }
    
    .main-paper{
        width: 100% !important;
        margin-top: 20px;
        background-color: white;
        border-radius: 10px;
        
        .main-paper-top{
            display: flex;
            flex-direction: column !important;
            align-items: center;

            .main-left{
                width: 200px;
                text-align: center;

                .left-top{
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-around;
                    font-size: 13px;
                    
                    .d{
                        border-radius: 50%;
                        background-color: rgb(5, 184, 79);
                        width: 30px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: white;
                    }
                    .h{
                        border-radius: 50%;
                        background-color: #FCB026;
                        width: 30px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: white;
                    }
                    .m{
                        border-radius: 50%;
                        background-color: #00276A;
                        width: 30px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: white;
                    }
                    .s{
                        border-radius: 50%;
                        background-color: gray;
                        width: 30px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: white;
                    }
                }
                .left-bottom{
                    display: flex;
                    justify-content: space-around;
                }
            }

            .main-center{
                margin-top: 10px;
                text-align: center;
                color: #00276A;
            }

        }

        .main-paper-bottom{
            margin: 5px 10px;

            .text{
                overflow: hidden !important;
                height: auto;
            }
        }
    }
}