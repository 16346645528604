*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-family: 'Roboto', sans-serif;
  font-family: 'Source Sans Pro', sans-serif;
  font-family: 'Ubuntu', sans-serif;
}

/* scroll bar styles */
::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #00276A;
}
::-webkit-scrollbar-thumb:hover {
  background: #00276A;
}
