.main {
  width: 100%;
  margin: 20px auto;
  height: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.main .card {
  position: relative;
  width: 25%;
  border-radius: 10px;
}

.main .card :hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
}

.main .currentImageTitle {
  position: absolute;
  top: 0px;
  left: 10%;
  color: #00276A;
  background-color: white;
  width: 80%;
  text-align: center;
  line-height: 20px;
  padding: 6px 3px;
  border-radius: 0px 0px 5px 5px;
  -webkit-box-shadow: 2px 2px 6px black;
          box-shadow: 2px 2px 6px black;
}

.main .main-paper {
  width: 70% !important;
  margin-left: 10px;
  border-radius: 10px;
}

.main .main-paper .main-paper-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.main .main-paper .main-paper-top .main-left {
  width: 200px;
  text-align: center;
}

.main .main-paper .main-paper-top .main-left .left-top {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  font-size: 13px;
}

.main .main-paper .main-paper-top .main-left .left-top .d {
  border-radius: 50%;
  background-color: #05b84f;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: white;
}

.main .main-paper .main-paper-top .main-left .left-top .h {
  border-radius: 50%;
  background-color: #FCB026;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: white;
}

.main .main-paper .main-paper-top .main-left .left-top .m {
  border-radius: 50%;
  background-color: #00276A;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: white;
}

.main .main-paper .main-paper-top .main-left .left-top .s {
  border-radius: 50%;
  background-color: gray;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: white;
}

.main .main-paper .main-paper-top .main-left .left-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.main .main-paper .main-paper-top .main-center {
  margin-top: 10px;
  text-align: center;
  color: #00276A;
}

.main .main-paper .main-paper-bottom {
  margin: 15px 10px;
}

.main .main-paper .main-paper-bottom .text {
  overflow: hidden !important;
  height: 110px !important;
}

@media only screen and (max-width: 1280px) {
  .main .main-paper {
    width: 60% !important;
  }
}

@media only screen and (max-width: 650px) {
  .main {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

.event {
  width: 300px;
  margin: 20px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important;
}

.event .card {
  background-color: #00276A;
  position: relative;
  border-radius: 10px;
}

.event .card :hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
}

.event .currentImageTitle {
  position: absolute;
  top: 0px;
  left: 10%;
  color: #00276A;
  background-color: white;
  width: 80%;
  text-align: center;
  line-height: 20px;
  padding: 6px 3px;
  border-radius: 0px 0px 5px 5px;
  -webkit-box-shadow: 2px 2px 6px black;
          box-shadow: 2px 2px 6px black;
}

.event .main-paper {
  width: 100% !important;
  margin-top: 20px;
  background-color: white;
  border-radius: 10px;
}

.event .main-paper .main-paper-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.event .main-paper .main-paper-top .main-left {
  width: 200px;
  text-align: center;
}

.event .main-paper .main-paper-top .main-left .left-top {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  font-size: 13px;
}

.event .main-paper .main-paper-top .main-left .left-top .d {
  border-radius: 50%;
  background-color: #05b84f;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: white;
}

.event .main-paper .main-paper-top .main-left .left-top .h {
  border-radius: 50%;
  background-color: #FCB026;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: white;
}

.event .main-paper .main-paper-top .main-left .left-top .m {
  border-radius: 50%;
  background-color: #00276A;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: white;
}

.event .main-paper .main-paper-top .main-left .left-top .s {
  border-radius: 50%;
  background-color: gray;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: white;
}

.event .main-paper .main-paper-top .main-left .left-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.event .main-paper .main-paper-top .main-center {
  margin-top: 10px;
  text-align: center;
  color: #00276A;
}

.event .main-paper .main-paper-bottom {
  margin: 5px 10px;
}

.event .main-paper .main-paper-bottom .text {
  overflow: hidden !important;
  height: auto;
}
